import Container from "@mui/system/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import Typography from "@mui/material/Typography";
import { borderTopColor } from "@mui/system";

export default function Footer() {
  return (
    <Box component="footer" sx={{borderTop:'1px solid',borderTopColor:'gray', paddingY:5}}>
      <Container maxWidth="xl">
        <Typography variant="h6" color='GrayText'>
            ©2024. Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );
}
