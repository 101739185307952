import Container from "@mui/system/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ExtensionIcon from "@mui/icons-material/Extension";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "@mui/material/Button";
import ScrollFade from "../UI/ScrollFade";

export default function Precios() {
  const features = [
    "Usuarios ilimitados",
    "Clientes ilimitados",
    "Centros ilimitados por cliente",
    "Módulos ilimitados por centro",
    "Jaulas ilimitadas por módulo",
    "Prueba gratis de 30 días",
    "Soporte técnico",
  ];

  return (
    <Box
      component="section"
      sx={{
        paddingY: "128px",
      }}
      className={"bg-section"}
      id="precios"
    >
      <ScrollFade direction="down">
        <Box
          component="div"
          sx={{
            textAlign: "center",
            marginBottom: 5,
          }}
        >
          <Typography variant="h4" className="text-piscium">
            Precios
          </Typography>
          <Typography variant="h2">Plan de pagos</Typography>
          <Typography variant="h6" color={"grayText"}>
            Paga solamente por lo que utilizas
          </Typography>
        </Box>
      </ScrollFade>
      <Container>
        <Grid container spacing={{ xs: 3, lg: 0 }} alignItems="center">
          <Grid xs={12} lg={6}>
            <ScrollFade direction="right">
              <Paper
                elevation={3}
                sx={{
                  padding: 5,
                  display: "flex",
                  flexDirection: "column",
                  padding: 2,
                  height: "100%",
                  borderRadius: 3,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: 2,
                  }}
                >
                  Costo Mensual
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: 2,
                  }}
                  className="text-piscium"
                >
                  3UF + 1UF Por Centro
                </Typography>
                <List
                  sx={{
                    width: "100%",
                  }}
                >
                  {features.map((item) => (
                    <ListItem key={item}>
                      <ListItemAvatar>
                        <CheckBoxIcon
                          color={"warning"}
                          sx={{ fontSize: "3rem" }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{ fontSize: "1.5rem" }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant="contained"
                  className="bg-piscium"
                  sx={{ marginTop: "auto", paddingY: 2, paddingX: 3 }}
                >
                  Contratar
                </Button>
              </Paper>
            </ScrollFade>
          </Grid>
          <Grid xs={12} lg={6}>
            <ScrollFade>
              <Paper
                elevation={3}
                className="bg-piscium-static"
                sx={{
                  padding: 8,
                  display: "flex",
                  flexDirection: "column",
                  borderTopLeftRadius: { xs: 8, lg: 0 },
                  borderBottomLeftRadius: { xs: 8, lg: 0 },
                  borderTopRightRadius: 8, // Set the value you want
                  borderBottomRightRadius: 8, // Set the value you want
                }}
              >
                <ExtensionIcon
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    fontSize: "8rem",
                    marginX: "auto",
                    color: "white",
                  }}
                ></ExtensionIcon>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: 2,
                  }}
                  color="white"
                >
                  Personaliza tu servicio
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    marginBottom: 2,
                  }}
                  color="white"
                >
                  Diseña un servicio personalizado a las necesidades especiales
                  de tu empresa
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    marginBottom: 2,
                  }}
                  color="white"
                >
                  Para negocios con grandes volúmenes de datos y/o modelos de
                  negocio únicos
                </Typography>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ marginTop: "auto", paddingY: 2, paddingX: 3 }}
                >
                  Contactanos
                </Button>
              </Paper>
            </ScrollFade>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
