import React from "react";
import { useInView } from "react-intersection-observer";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const getTransform = (direction, inView) => {
  const transformStyles = {
    up: "translateY(50px)",
    down: "translateY(-50px)",
    left: "translateX(50px)",
    right: "translateX(-50px)",
  };

  return inView ? "none" : transformStyles[direction];
};

const FadeInBox = styled(Box)(({ inView, direction }) => ({
  opacity: inView ? 1 : 0,
  transform: getTransform(direction, inView),
  transition: 'opacity 1s ease-out, transform 1s ease-out',
}));

const ScrollFade = ({ children, direction = "up" }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  return (
    <FadeInBox ref={ref} inView={inView} direction={direction}>
      {children}
    </FadeInBox>
  );
};

export default ScrollFade;
