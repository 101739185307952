import Container from "@mui/system/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FactoryIcon from "@mui/icons-material/Factory";
import CropFreeIcon from "@mui/icons-material/CropFree";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import PanoramaIcon from "@mui/icons-material/Panorama";
import PreviewIcon from "@mui/icons-material/Preview";
import FilterListIcon from "@mui/icons-material/FilterList";
import PageviewIcon from "@mui/icons-material/Pageview";
import PieChartIcon from '@mui/icons-material/PieChart';
import ScrollFade from "../UI/ScrollFade";
import video from "../../assets/sample-cat.mp4";

export default function Producto() {
  const features = [
    {
      icon: <PersonIcon className="text-piscium" />,
      description: "Gestionar usuarios",
    },
    {
      icon: <HandshakeIcon className="text-piscium" />,
      description: "Gestionar clientes",
    },
    {
      icon: <FactoryIcon className="text-piscium" />,
      description: "Gestionar centros y sus modulos",
    },
    {
      icon: <CropFreeIcon className="text-piscium" />,
      description: "Gestionar jaulas y sus esquemas",
    },
    {
      icon: <ListAltIcon className="text-piscium" />,
      description: "Personalizar tipos de informes acorde a tus faenas",
    },
    {
      icon: <DescriptionIcon className="text-piscium" />,
      description: "Crear y gestionar informes",
    },
    {
      icon: <PanoramaIcon className="text-piscium" />,
      description: "Añadir y gestionar las imagenes de tus informes",
    },
    {
      icon: <PreviewIcon className="text-piscium" />,
      description: "Visualizar tus informes online",
    },
    {
      icon: <PieChartIcon className="text-piscium" />,
      description: "Generar distintas metricas con los datos de tus informes",
    },
    {
      icon: <FilterListIcon className="text-piscium" />,
      description: "Exportar y compartir tus informes con tus clientes",
    },

    {
      icon: <PageviewIcon className="text-piscium" />,
      description:
        "Crear consolidados de tus informes mediante distintos filtros",
    },
    {
      icon: <PageviewIcon className="text-piscium" />,
      description:
        "Exportar y compartir tus consolidados de informes con tus clientes",
    },
  ];

  return (
    <section id="producto">
      <Container
        sx={{
          paddingY: "128px",
        }}
        maxWidth="xl"
      >
        <Grid container alignItems={"center"}>
          <Grid xs={12} lg={6}>
            <Paper
              component={"video"}
              sx={{
                borderRadius: 4,
                shadow: 5,
                width: "100%",
              }}
              controls
              elevation={20}
            >
              <source src={video} type="video/mp4" />
            </Paper>
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              paddingX: { xs: 0, lg: 5 },
            }}
          >
            <ScrollFade direction="left">
            <Typography
              className="text-piscium"
              variant="h5"
              sx={{ marginY: 2 }}
            >
              Como funciona
            </Typography>
            <Typography variant="h3" sx={{ marginY: 2 }}>
              PISCIUM REPORTS
            </Typography>
            <Typography
              variant="subtitle1"
              color="GrayText"
              sx={{ marginY: 2 }}
            >
              Con PISCIUM REPORTS tendrá la posibilidad de administrar de manera fácil y rápida todos los datos referentes a tus faenas del rubro acuícola, ya sean inspecciones, reparaciones, lavado, o cualquier otro tipo de faena que requiera reportar evidencia visual
              
            </Typography>
            <Typography
              variant="subtitle1"
              color="GrayText"
              sx={{ marginY: 2 }}
            >
              Con nuestra App tendrá la posibilidad de: 
            </Typography>
            {features.map((item) => (
              <Box component="div" display="flex" key={item.description}>
                {item.icon}
                <Typography variant="h6" component="span" ml={2}>
                  {item.description}
                </Typography>
              </Box>
            ))}
            </ScrollFade>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
