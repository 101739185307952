import Container from "@mui/system/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/system/Unstable_Grid";
import Typography from "@mui/material/Typography";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DescriptionIcon from "@mui/icons-material/Description";
import DevicesIcon from "@mui/icons-material/Devices";
import ScrollFade from "../UI/ScrollFade";

export default function Features() {
  const featureIconProps = {
    className: "text-piscium",
    sx: { fontSize: "6rem" },
  };

  const features = [
    {
      icon: <DescriptionIcon {...featureIconProps} />,
      title: "Informes personalizados",
      text: "Te ayudamos a crear y personalizar tus informes incluyendo en ellos la información que necesitas para reportar a tus clientes, ya sean tipos de informe, campos de formularios personalizados, y más",
    },
    {
      icon: <AnalyticsIcon {...featureIconProps} />,
      title: "Visualización y analisis",
      text: "Visualiza tus informes de manera online y accede a las analíticas generadas mediante la información ingresada, creando así una experiencia interactiva para tu empresa y sus clientes",
    },
    {
      icon: <DevicesIcon {...featureIconProps} />,
      title: "Facil de utilizar",
      text: "Interfaces intuitivas para el usuario, que se adaptan a cualquier tamaño de dispositivo, ya sean dispositivos moviles o de escritorio",
    },
  ];

  return (
    <section className="bg-section" style={{ paddingBottom: 100 }}>
      <Container maxWidth="xl">
        <ScrollFade style={{ width: "100%" }}>
          <Grid container spacing={3} rowSpacing={8}>
            {features.map((feature) => (
              <Grid xs={12} md={4} key={feature.title}>
                <Paper
                  elevation={3}
                  sx={{ padding: 2, height: "100%", borderRadius: 3 }}
                >
                  {feature.icon}
                  <Typography variant="h4" sx={{ marginBottom: 1 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="subtitle1" color={"GrayText"}>
                    {feature.text}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </ScrollFade>
      </Container>
    </section>
  );
}
