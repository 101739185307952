import "./App.css";
import { Button, Container } from "@mui/material";
import Navbar from "./components/UI/Navbar";
import Hero from "./components/Hero/Hero";
import Features from "./components/Features/Features";
import Precios from "./components/Precios/Precios";
import Contacto from "./components/Contacto/Contacto";
import Footer from "./components/UI/Footer";
import Producto from "./components/Producto/Producto";

function App() {
  return (
    <main>
      <Navbar />
      <Hero />
      <Features />
      <Producto />
      <Precios />
      <Contacto />
      <Footer />
    </main>
  );
}

export default App;
