import { useState } from "react";
import logo from "../../assets/logo1.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import "./Navbar.css";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{ width: 250, marginY: "auto" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box
        component={"img"}
        src={logo}
        height={90}
        sx={{
          marginLeft: {
            xs: "auto",
            lg: "0",
          },
        }}
      ></Box>
      <List>
        {["producto", "precios", "contacto"].map((text, index) => (
          <Button href={`#${text}`} sx={{width:'100%'}} key={text} >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PanoramaFishEyeIcon />
                </ListItemIcon>

                <ListItemText primary={text} className={'text-piscium'}/>
              </ListItemButton>
            </ListItem>
          </Button>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position={'relative'} sx={{ bgcolor: "white" }} component={"nav"}>
      <Toolbar>
        <Box
          sx={{
            display: { lg: "none" },
          }}
        >
          <Button variant={"text"} onClick={toggleDrawer(true)}>
            <MenuIcon
              className="text-piscium"
              sx={{ fontSize: "3rem" }}
            ></MenuIcon>
          </Button>
        </Box>
        <Box
          component={"img"}
          src={logo}
          height={90}
          sx={{
            marginLeft: {
              xs: "auto",
              lg: "0",
            },
          }}
        ></Box>
        <Typography
          variant="h5"
          className="text-piscium"
          ml={2}
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        >
          PISCIUM REPORTS 
        </Typography>
        <Box
          sx={{
            display: { xs: "none", lg: "block" },
            ml: "auto",
          }}
        >
          <Button variant={"text"} className={"text-piscium"} href="#producto">
            Producto
          </Button>
          <Button variant={"text"} className={"text-piscium"} href="#precios">
            Precios
          </Button>
          <Button variant={"text"} className={"text-piscium"} href="#contacto">
            Contacto
          </Button>
          <Button
            variant={"contained"}
            sx={{ marginLeft: 2, paddingY: 2, paddingX: 3 }}
            className="bg-piscium"
            href="http://app.piscium.cl/"
          >
            área de clientes
          </Button>
        </Box>
      </Toolbar>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
