import Container from "@mui/system/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from "../../assets/logo.png";
import ScrollFade from "../UI/ScrollFade";

export default function Contacto() {
  return (
    <section id="contacto">
      <Container
        sx={{
          paddingY: "128px",
        }}
        maxWidth="xl"
      >
        <Grid container spacing={1} alignItems="center">
          <Grid xs={12} lg={6}>
            <ScrollFade>
              <Typography variant="h4" className="text-piscium">
                Contáctanos
              </Typography>
              <Typography variant="h2">Podemos ayudarte</Typography>
              <Typography variant="subtitle1" color="gray">
                Queremos resolver todas tus inquietudes.
              </Typography>
              <Box
                src={logo}
                alt=""
                component="img"
                sx={{ width: "80%", marginY: 5 }}
              />

              <Typography variant="subtitle1" color="gray">
                Llámanos al:
              </Typography>
              <Typography variant="h6" color="black">
                (+56) 9 7774 6447
              </Typography>

              <Typography
                variant="subtitle1"
                color="gray"
                sx={{ marginTop: 3 }}
              >
                Escríbenos a:
              </Typography>
              <Typography variant="h6" color="black">
                contacto@piscium.cl
              </Typography>
            </ScrollFade>
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{ padding: { md: 8 }, marginTop: { xs: 5, md: 0 } }}
          >
            <Box component="form">
              <TextField
                id="outlined-basic"
                label="Nombre Completo"
                variant="outlined"
                sx={{ width: "100%", marginBottom: 3 }}
              />
              <TextField
                id="outlined-basic"
                label="Correo electronico"
                variant="outlined"
                sx={{ width: "100%", marginBottom: 3 }}
              />
              <TextField
                id="outlined-basic"
                label="Mensaje"
                variant="outlined"
                multiline
                rows={6}
                sx={{ width: "100%", marginBottom: 3 }}
              />
              <Button
                className="bg-piscium"
                variant="contained"
                sx={{ width: "100%", paddingY: 2, paddingX: 3 }}
              >
                Enviar
              </Button>
              <Typography
                variant="subtitle1"
                color={"gray"}
                sx={{ marginY: 3 }}
              >
                Te contestaremos a la brevedad
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
