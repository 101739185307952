import { Container } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/system/Unstable_Grid";
import Typography from "@mui/material/Typography";
import dashboard from "../../assets/dashboard3.png";
import Button from "@mui/material/Button";
import ScrollFade from "../UI/ScrollFade";

export default function Hero() {
  return (
    <section className="bg-section">
      <Container
        sx={{
          paddingY: "64px",
        }}
        maxWidth="xl"
      >
        <Grid container spacing={1} rowSpacing={5} alignItems="center">
          <Grid xs={12} md={6}>
            <ScrollFade direction="right">
              <Typography variant="h2" sx={{ marginBottom: 3 }}>
                Simplifica y transforma tus informes en{" "}
                <span className="text-piscium">resultados</span>
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  paddingX: 1,
                }}
                color={"GrayText"}
              >
                Con <span className="text-piscium">PISCIUM REPORTS</span>{" "}
                administra de manera fácil y rápida todos los datos referentes a
                tus faenas del rubro acuícola, ya sean inspecciones,
                reparaciones, lavado, o cualquier otro tipo de faena que
                requiera reportar evidencia visual.
              </Typography>
              <Button
                variant={"contained"}
                sx={{ marginTop: 3, marginRight: 2, paddingY: 2, paddingX: 3 }}
                className="bg-piscium"
                href="#producto"
              >
                Ver Más
              </Button>
              <Button
                variant={"contained"}
                sx={{ marginTop: 3, paddingY: 2, paddingX: 3 }}
                color="warning"
                href="#contacto"
              >
                Contacto
              </Button>
            </ScrollFade>
          </Grid>

          <Grid xs={12} md={6}>
            <ScrollFade direction="left">
              <Paper
                component={"img"}
                src={dashboard}
                alt="Dashboard image"
                sx={{
                  borderRadius: 4,
                  shadow: 5,
                  width: "100%",
                }}
                elevation={20}
              />
            </ScrollFade>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
